import React, { useEffect, useState, useMemo } from 'react';
import { Skeleton } from '@mui/material';
import ReactEcharts from 'echarts-for-react';
import { getCurrentDate, formatCurrency } from '../common';
import { theme } from '../Theme'
import moment from 'moment';
import { debounce } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { setTreatmentProviderChartLegend, setTreatmentProviderChartDateRange } from '../redux/slices/medicalsTreatmentsSlice'
import CollapseCard from '../CommonComponents/CollapseCard';

const MedicalTreatmentsByDateChart = ({ documentData, carrierMedicals }) => {
    const dispatch = useDispatch();
    const authenticatedMeds =  useSelector((state) => state.Medicals.medicals);
    const medicals = carrierMedicals || authenticatedMeds;    
    const { medicalTreatments } = medicals;
    const { treatmentProviderChartLegend } = useSelector((state) => state.MedicalsTreatments);
    const { documentDataLoading} = useSelector(state => state.Document);
    const { medicalsLoading } = useSelector(state => state.Medicals);
    const { dateOfLoss, demandDetails } = documentData.claimInfo;
    const dateOfDemand = demandDetails?.demandWrittenDate;
    const today = getCurrentDate();


    const { demandS3RegenerationLoadingData } = useSelector((state) => state.Pdf);
    const isPdfRegenLoading = Boolean(demandS3RegenerationLoadingData[documentData.documentId]);

    const markLineArray = [
        ...(today ? [{
            name: 'Today', xAxis: today, label: {
                formatter: 'Today', position: 'end', rotate: 0
            },
            tooltip: { formatter: (params) => `Today: ${moment(params.value).format('MM/DD/YYYY')}` }
        }] : []
        ),
        ...(dateOfLoss && dateOfLoss !== 'Invalid date' ? [{
            name: 'DOL', xAxis: dateOfLoss, label: {
                formatter: 'DOL', position: 'end', rotate: 0
            },
            tooltip: { formatter: (params) => 'Date of Loss: ' + moment(params.value).format('MM/DD/YYYY') }
        }] : []),
        ...(dateOfDemand && dateOfDemand !== 'Invalid date' ? [{
            name: 'DOD', xAxis: dateOfDemand,
            label: { formatter: 'DOD', position: 'end', rotate: 0 },
            tooltip: { formatter: (params) => 'Date of Demand: ' + moment(params.value).format('MM/DD/YYYY') }
        }] : [])
    ];

    const allTreatmentNames = medicalTreatments.map((treatment) => treatment.treatmentFacility.toUpperCase()).reduce((acc, facility) => {
        const uppercaseFacility = facility.toUpperCase()
        if (!acc.includes(uppercaseFacility)) {
            acc.push(uppercaseFacility)
        }
        return acc
    }, [])

    const displayTreatmentsGroupedByFacility = reduceTreatmentByFacility(medicalTreatments);
    const arrayOfAllFacilities = Object.keys(displayTreatmentsGroupedByFacility)
    const arrayOfAllTreatmentDates = medicalTreatments.map((treatment) => treatment.treatmentDate).sort((a, b) => Date.parse(a) - Date.parse(b));
    const arrayOfAllDisplayTreatments = Object.keys(displayTreatmentsGroupedByFacility).map((data) => displayTreatmentsGroupedByFacility[data]).sort((a, b) => Date.parse(a[0].treatmentDate) - Date.parse(b[0].treatmentDate));

    useEffect(() => {
        const originalLegendState = arrayOfAllFacilities.reduce((acc, facility) => {
            acc[facility] = true
            return acc
        }, {})
        dispatch(setTreatmentProviderChartLegend(originalLegendState))
    }, [])

    useEffect(() => {
        const legendEntryLength = Object.keys(treatmentProviderChartLegend).length
        const arrayOfAllFacilitiesLength = arrayOfAllFacilities.length
        if (legendEntryLength !== arrayOfAllFacilitiesLength) {
            const newLegendKeysObject = arrayOfAllFacilities.reduce((acc, facility) => {
                acc[facility] = treatmentProviderChartLegend[facility] === undefined ? true : treatmentProviderChartLegend[facility]
                return acc
            }
                , {})
            dispatch(setTreatmentProviderChartLegend(newLegendKeysObject))
        }
    }, [arrayOfAllFacilities])

    const treatmentFacilityArray = arrayOfAllDisplayTreatments.map((entry => {
        const facilityName = entry[0].treatmentFacility.toUpperCase()
        const specificTreatmentData = entry

        const billedAmountByDate = specificTreatmentData.reduce((acc, treatment) => {
            const { treatmentDate, billedAmount } = treatment;
            if (acc[treatmentDate] !== undefined) {
                acc[treatmentDate] += +billedAmount
            } else {
                acc[treatmentDate] = +billedAmount
            }
            return acc
        }, {})

        const arrayOfDates = Object.keys(billedAmountByDate).sort((a, b) => Date.parse(a) - Date.parse(b));
        const arrayOfAmounts = Object.values(billedAmountByDate).sort((a, b) => Date.parse(a) - Date.parse(b));

        return {
            name: facilityName,
            type: 'bar',
            barGap: 0,
            barWidth: 8,
            emphasis: {
                focus: 'series'
            },
            color: theme.visuals.categorical[allTreatmentNames.indexOf(facilityName) % theme.visuals.categorical.length],
            data: arrayOfAllTreatmentDates.map((date, i) => {
                if (arrayOfDates.includes(date)) {
                    return [arrayOfDates.shift(), arrayOfAmounts.shift()]
                } else {
                    return [date, '-']
                }
            }
            )
        }
    }))

    const twoDays = 1000 * 60 * 60 * 24 * 2;
    const marklineDates = markLineArray.map((entry) => Date.parse(entry.xAxis))
    const minMarklineDate = Math.min(...marklineDates)
    const maxMarklineDate = Math.max(...marklineDates)
    const maxMedicalTreatmentDate = Date.parse(arrayOfAllTreatmentDates[arrayOfAllTreatmentDates.length - 1])
    const minMedicalTreatmentDate = Date.parse(arrayOfAllTreatmentDates[0])
    const maxXAxis = (maxMedicalTreatmentDate >= maxMarklineDate ? maxMedicalTreatmentDate : maxMarklineDate) + twoDays
    const minXAxis = (minMedicalTreatmentDate <= minMarklineDate ? minMedicalTreatmentDate : minMarklineDate) - twoDays
    const xLength = maxXAxis - minXAxis
    const xMaxZoom = (((maxMedicalTreatmentDate + twoDays - minXAxis) / xLength) * 100).toFixed(2)
    const xMinZoom = (((minMedicalTreatmentDate < Date.parse(dateOfLoss) ? minMedicalTreatmentDate : Date.parse(dateOfLoss)) - twoDays - minXAxis) / xLength) * 100

    const [zoomPercentages, setZoomPercentages] = useState({
        start: xMinZoom,
        end: xMaxZoom
    })

    useEffect(() => {
        const newZoomPercentages = {
            start: xMinZoom,
            end: xMaxZoom
        }
        setZoomPercentages(newZoomPercentages)
    }, [xMinZoom, xMaxZoom])

    const option = {
        animation: false,
        tooltip: {
            formatter: (params) => {
                const date = params.data[0] ? moment(params.data[0]).format('MM/DD/YYYY') : '';
                const amount = params.data[1]?.toFixed(2) || 0;
                const treatmentFacility = params.seriesName.toUpperCase();

                if (params.componentType === 'markLine') {
                    const name = params.name
                    const date = params.value
                    return `<strong>${name}: ${date}</strong><br/>`
                }
                return `<strong>Date: ${date}</strong><br>${params.marker}${treatmentFacility.toUpperCase()}:  ${formatCurrency(amount)}<br/>`
            }
        },
        legend: {
            data: allTreatmentNames,
            selected: treatmentProviderChartLegend,
            itemGap: 16,
            type: "scroll",
            padding: 8
        },
        toolbox: {
            show: true,
            orient: 'vertical',
            left: 'right',
            top: 'center',
        },
        xAxis: [
            {
                type: 'time',
                splitLine: {  // Set splitLine to show gridlines
                    show: true,
                },
                axisTick: { show: false },
                axisLabel: {
                    formatter: value => moment(value).format('M/D/YY'),
                    rotate: 45,
                    hideOverlap: true,
                    interval: 0,
                },
                min: (val) => minXAxis,
                max: (val) => maxXAxis,
            }
        ],
        yAxis: [
            {
                type: 'value',
                splitLine: {  // Set splitLine to show gridlines
                    show: true,
                },
                axisLabel: {
                    formatter: value => formatCurrency(value).split('.')[0]
                }
            }
        ],
        dataZoom: [
            {
                type: 'slider',
                xAxisIndex: 0,
                filterMode: 'none',
                labelFormatter: value => moment(value).format('MM/DD/YYYY'),
                start: zoomPercentages.start,
                end: zoomPercentages.end

            },
        ],
        grid: {
            left: '16px',
            right: '16px',
            bottom: '12%',
            top: '12%',
            containLabel: true,
        },
        series: treatmentFacilityArray.map((entry, idx) => {
            const listOfDates = entry.data.filter((entry) => entry[1] !== '-').map((entry) => entry[0]);
            const matchingMarklineCopyIndex = markLineArray.findIndex((entry) => listOfDates.includes(entry.xAxis))
            const markLineData = idx === treatmentFacilityArray.length - 1 ? markLineArray : matchingMarklineCopyIndex !== -1 ? markLineArray.splice(matchingMarklineCopyIndex, 1) : []

            return {
                ...entry,
                markLine: {
                    symbol: 'none',
                    data: markLineData,
                    animation: false,
                    lineStyle: {
                        color: 'black',
                    },
                    zlevel: 2,
                },
            };
        })
    };

    const onDataZoom = (params, b) => {
        const { start, end } = params
        const startValue = minXAxis + ((start / 100) * xLength)
        const endValue = minXAxis + ((end / 100) * xLength)
        dispatch(setTreatmentProviderChartDateRange({ start: startValue, end: endValue }))
        setZoomPercentages({ start: start, end })
    }

    const onEvents = {
        //chart legend click event
        legendselectchanged: (params) => {
            const allChartLegendKeys = Object.keys(treatmentProviderChartLegend);
            const facilitiesSelectedPriorToClick = Object.keys(treatmentProviderChartLegend).filter((key) => treatmentProviderChartLegend[key] === true);
            const allFacilitiesWereSelectedPriorToClick = facilitiesSelectedPriorToClick.length === allChartLegendKeys.length;
            const onlyOneFacilitySelected = facilitiesSelectedPriorToClick.length === 1;
            const justClickedFacility = params.name;

            const newLegendKeysObject = arrayOfAllFacilities.reduce((acc, facility) => {
                if (allFacilitiesWereSelectedPriorToClick) {
                    acc[facility] = facility === justClickedFacility;
                } else if (onlyOneFacilitySelected && justClickedFacility === facilitiesSelectedPriorToClick[0]) {
                    acc[facility] = true;
                }
                else {
                    acc[facility] = params.selected[facility];
                }
                return acc;
            }
                , {})
            dispatch(setTreatmentProviderChartLegend(newLegendKeysObject))
        },

        dataZoom: debounce(onDataZoom, 100),
    }

    const MemoizedChart = useMemo(() => {
        return <ReactEcharts
            style={{ height: '400px', width: '100%', paddingRight: '10px' }}
            option={option}
            notMerge={true}
            theme={'light'}
            onEvents={onEvents}
        />
    }
        , [treatmentProviderChartLegend, zoomPercentages, medicalTreatments])



    return (
        <CollapseCard title="Billed amount by date" expanded={true}>
            {(documentDataLoading || medicalsLoading || isPdfRegenLoading) ? <Skeleton variant="rectangular" height={400} /> : MemoizedChart}
        </CollapseCard>

    );
};

export default MedicalTreatmentsByDateChart;

//helpers

const reduceTreatmentByFacility = (displayTreatments) => displayTreatments.reduce((acc, treatment) => {
    const treatmentFacility = treatment.treatmentFacility.toUpperCase();
    if (acc[treatmentFacility]) {
        acc[treatmentFacility].push(treatment);
    } else {
        acc[treatmentFacility] = [treatment];
    }
    return acc;
}
    , {})
