import React, { useEffect, useState, useMemo } from 'react';
import { Form, Field } from 'react-final-form';
import { TextField, Switches, Radios, Autocomplete } from 'mui-rff'
import { Stack, Button, InputAdornment, Dialog, IconButton, CircularProgress, DialogContent, DialogTitle, Typography, MenuItem, FormControl, Toolbar, Tabs, Tab, DialogActions, Divider, Box, TextField as MuiTextField, Badge } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { AutoAwesome, Close } from '@mui/icons-material';
import Title from '../../CommonComponents/Title';
import { theme } from '../../Theme';
import ResizableWrapWithPdfViewer from '../../CommonComponents/ResizableWrapWithPdfViewer';
import ConfirmationDialog from '../../dialogs/ConfirmationDialog';
import ConfiguredRichTextEditor from '../RichTextEditor/ConfiguredRichTextEditor';
import { Transition } from '../../Transition';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAuthorFormData } from '../../redux/thunks/demandData';
import { setDemandDetailsEditOpen } from '../../redux/slices/demandDomSlice';
import TemplateUpdateAlert from '../../DemandComponents/TemplateUpdateAlert';
import { shouldDisplayAlert } from '../../DemandComponents/TemplateUpdateAlert';
import { formatDecimal, cleanNumber, formatDateForForm, getCurrentDate, formatPhoneNumber, userHasPermission } from '../../common';
import { isPrecedentUser, isPrecedentAdmin } from '../../common-roles';
import { PRECEDENT_VIEW_TYPE, LAW_FIRM_VIEW_TYPE } from '../../common-view-types';
import { fetchDocumentData } from '../../redux/thunks/documentData';
import { setDocumentDataLoading } from '../../redux/slices/documentSlice';
import { coverageList, stateLabelValues } from '../../common-data';
import { joinNames, mapCustomerDemandFieldsToDocumentFields, validate, NO_TEMPLATE, buildUserAutoCompleteData, getSingleSelectAutoCompleteFilteredOptions, getMultiSelectAutoCompleteFilteredOptions } from './FormHelpers'
import { parseNestedObject } from '../../helpers/formHelpers';
import { getAvailableTemplatesAndCustomFieldsForCustomer } from '../../api';
import moment from 'moment';
import { DiscardWork } from '../../dialogs/DialogText';
import { debounce } from 'lodash';
import EditDemandDetailsSkeleton from './EditDemandDetailsSkeleton';
import { useGetPdfUrlQuery } from '../../services/pdf/pdfApi';
import { deepPurple } from '@mui/material/colors';

const badgeStyleProps = { '.MuiBadge-badge': { right: '6px' } };

const EditDemandDetails = ({
  editOverviewOpen,
  user,
  userData,
  handleSave,
}) => {

  const dispatch = useDispatch();
  const documentDataState = useSelector((state) => state.Document);
  const { templateBody, templateBodyForPinnedVersion } = useSelector((state) => state.Template);
  const authorState = useSelector((state) => state.Author);
  const { documentData, documentMetaData, documentDataLoading } = documentDataState;
  const documentId = documentData?.documentId;

  //RTK Query
  const { data: { demandS3Url } = {} } = useGetPdfUrlQuery(documentId);

  const customerState = useSelector((state) => state.Customer);
  const { customerData } = customerState;
  const viewType = isPrecedentUser(userData) ? PRECEDENT_VIEW_TYPE : LAW_FIRM_VIEW_TYPE;
  const [isDocumentFetched, setIsDocumentFetched] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [tabDirtyState, setTabDirtyState] = useState({ 0: false, 1: false });

  const { approvers } = authorState;
  const users = authorState?.users || [];

  const handleTabChange = (e) => setTabValue(e.target.tabIndex);
  const closeEditView = () => dispatch(setDemandDetailsEditOpen(false));

  const approverIds = approvers?.map(approver => approver.userId) || [];

  const attorneys = users ? users.filter(user => approverIds.includes(user.userId)) : [];
  if (userData && !attorneys.find(attorney => attorney?.userId === userData?.userId)) {
    attorneys.unshift({ userId: userData.userId, firstName: userData.firstName, lastName: userData.lastName, contactInfo: { phoneNumber: userData.phone, emailAddress: userData.emailAddress } })
  }

  const attorney_auto_complete_data = buildUserAutoCompleteData(attorneys);
  const user_data_for_auto_completes = buildUserAutoCompleteData(users);
  const primaryContact_auto_complete_data = user_data_for_auto_completes;
  const collaborators_auto_complete_data = user_data_for_auto_completes;

  const customerDemandFields = useMemo(
    () => customerData?.customerEntityData?.customDemandFieldsList || [],
    [customerData?.customerEntityData?.customDemandFieldsList]
  );
  const documentDemandFields = documentData.customDemandFieldsList;

  const [templateDialogState, setTemplateDialogState] = useState({
    confirmNoTemplate: {
      open: false,
      text1: 'The existing demand letter will be removed from documents.',
      text2: 'Remember to upload a replacement.',
      buttonText1: 'Cancel',
      buttonText2: 'Confirm selection',
    },
    confirmTemplateChange: {
      open: false,
      text1: 'A pre-filled demand letter will be added to the documents.',
      text2: 'Remember to remove uploaded demand letters.',
      buttonText1: 'Cancel',
      buttonText2: 'Use selected template',
    },
  });

  const [availableTemplates, setAvailableTemplates] = useState([]);
  const [currentTemplate, setCurrentTemplate] = useState(null);

  useEffect(() => {
    if (user && documentId) {
      dispatch(setDocumentDataLoading(documentId));
      dispatch(fetchDocumentData({ documentId, user }))
      setIsDocumentFetched(true);

      const fetchTemplates = async () => {
        try {
          const result = await getAvailableTemplatesAndCustomFieldsForCustomer(user, isPrecedentAdmin(userData) ? documentData?.customerId : null);
          const templates = result.templates;
          templates.unshift({ templateName: NO_TEMPLATE, templateId: NO_TEMPLATE });

          if (Array.isArray(templates)) {
            setAvailableTemplates(templates);
          } else {
            console.error('Expected an array from getAvailableTemplatesForCustomer, but received:', templates);
          }
        } catch (error) {
          console.error('Error fetching templates:', error);
        }
      };
      fetchTemplates();

      dispatch(
        fetchAuthorFormData({
          token: user.signInUserSession.accessToken.jwtToken,
          user: user,
          ...(viewType === PRECEDENT_VIEW_TYPE && { customerId: customerData?.customerId }),
          hasPermission: userHasPermission('DemandEdit', userData) || userHasPermission('DemandCompose', userData)
        })
      );
    }
  }, [user, userData, documentId, documentData?.customerId, customerData?.customerId, viewType, dispatch]);

  const initialDemandTemplateId = documentMetaData?.demandTemplateId ? availableTemplates.find(template => template.templateId === documentMetaData?.demandTemplateId)?.templateId : NO_TEMPLATE

  const initialValues = useMemo(() => {
    const initialAttorney = { label: joinNames(documentData?.sendingFirm?.attorney), value: documentData?.sendingFirm?.attorney };
    const initialPrimaryContact = { label: joinNames(documentData?.sendingFirm?.primaryContact), value: documentData?.sendingFirm?.primaryContact };
    const attorneyIsPrimaryContact = joinNames(documentData?.sendingFirm?.attorney) === joinNames(documentData?.sendingFirm?.primaryContact);

    const initialCollaborators = (documentData?.sendingFirm?.caseManagers ? documentData?.sendingFirm?.caseManagers.map(manager => (
      { label: joinNames(manager), value: manager }
    )) : undefined);

    return {
      ...documentData,
      recipientCarrier: {
        ...documentData?.recipientCarrier,
        contactInfo: {
          ...documentData?.recipientCarrier?.contactInfo,
          phoneNumber: formatPhoneNumber(documentData?.recipientCarrier?.contactInfo?.phoneNumber),
          address: {
            ...documentData?.recipientCarrier?.contactInfo?.address,
            zipCode: documentData?.recipientCarrier?.contactInfo?.address?.zipCode,
          },
        },
      },
      recipientAdjuster: {
        ...documentData?.recipientAdjuster,
        contactInfo: {
          ...documentData?.recipientAdjuster?.contactInfo,
          phoneNumber: formatPhoneNumber(documentData?.recipientAdjuster?.contactInfo?.phoneNumber),
        },
      },
      metadata: {
        ...documentData?.metadata,
        demandTemplateId: initialDemandTemplateId,
      },
      claimInfo: {
        ...documentData?.claimInfo,
        dateOfLoss: formatDateForForm(documentData?.claimInfo?.dateOfLoss),
      },
      demandDetails: {
        ...documentData?.demandDetails,
        demandResponseDueDate: formatDateForForm(documentData?.demandDetails?.demandResponseDueDate),
        displayDemandAmount: typeof documentData?.demandDetails?.displayDemandAmount === 'boolean' ? documentData?.demandDetails?.displayDemandAmount : false,
        policyLimitDemandIndicator: typeof documentData?.demandDetails?.policyLimitDemandIndicator === 'boolean' ? documentData?.demandDetails?.policyLimitDemandIndicator : false,
        demandOfferToSettleAmount: documentData?.demandDetails?.policyLimitDemandIndicator
          ? documentData?.claimInfo?.insuredPolicyLimitsAsUnderstoodByFirm
          : documentData?.demandDetails?.demandOfferToSettleAmount,

      },
      customDemandFieldsList: mapCustomerDemandFieldsToDocumentFields(customerDemandFields, documentDemandFields),
      mutators: {
        attorneyIsPrimaryContact,
        collaborators: documentData?.sendingFirm?.caseManagers?.map(manager => joinNames(manager)) || [],
      },
      autoCompletes: {
        collaborators: initialCollaborators,
        attorney: initialAttorney,
        primaryContact: attorneyIsPrimaryContact ? initialAttorney : initialPrimaryContact,
        carrier: {
          label: 'validation placeholder for read only/ unused field',
          value: 'validation placeholder for read only/ unused field'
        }
      },
    };
  }, [customerDemandFields, documentDemandFields, documentData, initialDemandTemplateId]);

  const [dialogState, setDialogState] = useState({
    discardChanges: false
  });

  const handleSaveClick = async (values) => {
    const saveBody = parseNestedObject({
      ...values,
      customDemandFieldsList: mapCustomerDemandFieldsToDocumentFields(customerDemandFields, values?.customDemandFieldsList || []),
      demandDetails: {
        ...values.demandDetails,
        demandOfferToSettleAmount: cleanNumber(values.demandDetails.demandOfferToSettleAmount)
      },
      claimInfo: {
        ...values.claimInfo,
        insuredPolicyLimitsAsUnderstoodByFirm: cleanNumber(values.claimInfo.insuredPolicyLimitsAsUnderstoodByFirm),
      },
      sendingFirm: {
        ...values.sendingFirm,
        //use autoComplete values to populate respective fields on sendingFirm object onSave
        attorney: values?.autoCompletes?.attorney?.value,
        primaryContact: values?.mutators?.attorneyIsPrimaryContact ? values?.autoCompletes?.attorney?.value : values?.autoCompletes?.primaryContact?.value,
        caseManagers: (values?.autoCompletes?.collaborators || []).map(collaborator => collaborator.value)
      },
    });
    delete saveBody.mutators;
    delete saveBody.autoCompletes;
    closeEditView();
    handleSave(true, saveBody);
  };

  const a11yProps = (index) => ({
    id: `edit-demand-details-tab-${index}`,
    'aria-controls': `edit-demand-details-tabpanel-${index}`,
  });

  const confirmationDialogProps = {
    dialogOpen: dialogState.discardChanges,
    dialogText: DiscardWork.text,
    cancelButtonText: DiscardWork.cancelText,
    okButtonText: DiscardWork.confirmText,
    handleCancel: () => {
      setDialogState({ ...dialogState, discardChanges: false });
    }
  };

  const isDocumentDataLoadingForThisDoc = documentDataLoading === documentId;
  const shouldShowTemplateUpdateAlert = shouldDisplayAlert(templateBody, templateBodyForPinnedVersion, documentMetaData);

  return (
    <div>
      <Dialog
        open={editOverviewOpen}
        TransitionComponent={Transition}
        aria-describedby="alert-dialog-slide-description"
        fullScreen={true}
        fullWidth={true}
      >

        <Form
          validate={validate}
          onSubmit={() => null}
          initialValues={initialValues}
          render={({
            handleSubmit,
            form,
            submitting,
            values,
            valid,
            errors,
            dirty,
            touched,
            pristine,
            fields,
            dirtyFields,
            dirtySinceLastSubmit,
          }) => {

            if (tabValue === 0 && dirty !== tabDirtyState[0]) setTabDirtyState({ ...tabDirtyState, 0: dirty });
            if (tabValue === 1 && dirty !== tabDirtyState[1]) setTabDirtyState({ ...tabDirtyState, 1: dirty });
            const collaboratorsDirty = JSON.stringify(values?.mutators?.collaborators.sort()) !== JSON.stringify(initialValues?.mutators?.collaborators.sort());
            const isEitherTabDirty = Object.values(tabDirtyState).some((tabDirty) => tabDirty) || collaboratorsDirty;

            const noTemplatedInitially = initialDemandTemplateId === NO_TEMPLATE;

            if (!noTemplatedInitially) {
              setCurrentTemplate(
                availableTemplates.find((template) => template.templateId === values.metadata?.demandTemplateId)
              );
            }

            const handleTemplateCancelChange = () => {
              form.change('metadata.demandTemplateId', documentMetaData?.demandTemplateId || NO_TEMPLATE);
              handleTemplateDialogClose();
            }

            const handleTemplateOkChange = () => {
              setCurrentTemplate(
                availableTemplates.find((template) => template.templateId === values.metadata?.demandTemplateId)
              );
              handleTemplateDialogClose();
            }



            const handleTemplateDialogClose = () => {
              setTemplateDialogState({
                confirmNoTemplate: { ...templateDialogState.confirmNoTemplate, open: false },
                confirmTemplateChange: { ...templateDialogState.confirmTemplateChange, open: false },
              });
            };

            const handleCloseButtonClick = () => {
              isEitherTabDirty ? setDialogState({ discardChanges: true }) : closeEditView()
            };

            return (
              <form id="editDemandDetails" onSubmit={handleSubmit}>
                <DialogTitle
                  className="dialog-title-blue"
                  sx={{ height: '60px', width: '100%', zIndex: 1000 }}
                  display="flex"
                  alignItems="center"
                  position={'fixed'}
                >
                  <Stack
                    direction={'row'}
                    style={{ width: '100%' }}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                  >
                    <Stack direction={'row'} spacing={2} alignItems={'center'}>
                      <IconButton
                        onClick={handleCloseButtonClick}
                        aria-label="close"
                        sx={{ '&:hover': { backgroundColor: 'rgba(255, 255, 255, 0.15)' } }}
                      >
                        <Close color={'white'} />
                      </IconButton>
                      <Title color={theme.palette.white.main}>Edit demand details</Title>
                    </Stack>
                    <Button
                      disabled={submitting || !valid || !(isEitherTabDirty)}
                      variant="contained"
                      color="white"
                      sx={{
                        '&:disabled': {
                          backgroundColor: 'rgba(255,255,255,0.2)',
                        },
                      }}
                      onClick={e => {
                        e.preventDefault();
                        handleSaveClick(values);
                      }}
                      startIcon={(submitting) && <CircularProgress size={20} color="inherit" />} // Show spinner when loading
                    >
                      {submitting ? 'Saving...' : 'Save'}
                    </Button>
                  </Stack>
                </DialogTitle>
                <Toolbar sx={{ height: '60px' }} />

                <DialogContent sx={{ marginTop: '0px', paddingTop: '0px', overflow: 'visible' }}>
                  <div style={{ width: '100%', display: 'flex' }}>
                    {isDocumentFetched && !isDocumentDataLoadingForThisDoc && (
                      <ResizableWrapWithPdfViewer
                        defaultWidth={'60%'}
                        pdfHeight={'85vh'}
                        isPDFSticky
                        user={user}
                        documentId={documentId}
                      >
                        <div style={demandS3Url ? { minHeight: '90vh' } : {}}>

                          {isDocumentDataLoadingForThisDoc && (
                            <EditDemandDetailsSkeleton />
                          )}

                          {!isDocumentDataLoadingForThisDoc && (
                            <Tabs
                              value={tabValue}
                              onChange={handleTabChange}
                              aria-label="Edit demand details form tabs"
                              TabIndicatorProps={{
                                style: {
                                  backgroundColor: !valid && tabValue === 0 ? theme.palette.error.main : theme.palette.primary.main,
                                }
                              }}
                            >
                              <Tab label="Demand details" {...a11yProps(0)} tabIndex={0}
                                sx={{
                                  color: !valid && theme.palette.error.main,
                                  '&.Mui-selected': {
                                    color: !valid && theme.palette.error.main
                                  }
                                }}
                              />
                              <Tab label="Customize demand letter" {...a11yProps(1)} tabIndex={1}
                                icon={shouldShowTemplateUpdateAlert && <Badge badgeContent='1' color="error" sx={badgeStyleProps} />}
                                iconPosition="start" />
                            </Tabs>
                          )}

                          {!isDocumentDataLoadingForThisDoc && (
                            <Stack spacing={2}
                              sx={{
                                paddingRight: 2,
                                pb: 2,
                                height: 'calc(100vh - 120px)',
                                overflow: 'scroll'
                              }}>
                              {tabValue === 0 && (
                                <Grid container spacing={2} sx={{ mb: 5 }}>

                                  <Stack spacing={2} width={"100%"} sx={{ mt: 2, mb: 5 }} divider={<Divider flexItem />}>

                                    <Stack>
                                      <Typography variant="body1" color="primary" sx={{ mb: 1.5 }}>Case details</Typography>
                                      <Grid container spacing={2}>

                                        <Grid item xs={12} md={6}>
                                          <TextField fullWidth required label="Matter number or Case ID" size='small' name='sendingFirm.firmCaseNumber' error={errors.sendingFirm?.firmCaseNumber} helperText={errors.sendingFirm?.firmCaseNumber} />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                          <TextField fullWidth label="Claim number" size='small' name='claimInfo.claimNumber' />
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                          <Stack spacing={1}>
                                            <TextField fullWidth required label="Client" name='claimInfo.claimant.firstName' size='small'
                                              error={errors.claimInfo?.claimant?.firstName}
                                              helperText={errors.claimInfo?.claimant?.firstName}
                                              onChange={(e) => {
                                                form.change('claimInfo.claimant.firstName', e.target.value)
                                                if (values.claimInfo?.clientIsInjuredParty) {
                                                  form.change('claimInfo.injuredPartyFullName', e.target.value);
                                                }
                                              }}
                                            />
                                            <Switches
                                              name="claimInfo.clientIsInjuredParty"
                                              onChange={(e) => {
                                                const checked = e.target.checked;
                                                form.change('claimInfo.clientIsInjuredParty', checked)
                                                if (checked) {
                                                  form.change('claimInfo.injuredPartyFullName', values?.claimInfo?.claimant?.firstName)
                                                } else {
                                                  form.change('claimInfo.injuredPartyFullName', '')
                                                }
                                              }}
                                              data={{ label: 'Client is injured party', value: true }}
                                              color="secondary"
                                            />
                                            {!values.claimInfo?.clientIsInjuredParty &&
                                              <Box
                                                sx={{
                                                  paddingLeft: 6,
                                                  paddingBottom: 2
                                                }}
                                              >
                                                <TextField fullWidth required label="Injured party" name='claimInfo.injuredPartyFullName' size='small'
                                                  error={errors.claimInfo?.injuredPartyFullName}
                                                  helperText={errors.claimInfo?.injuredPartyFullName}
                                                />
                                              </Box>
                                            }
                                          </Stack>
                                        </Grid>

                                        <Grid item xs={12} md={12}>
                                          <Stack spacing={1}>
                                            <TextField fullWidth required label="Insured" name="claimInfo.insuredFirstName" size='small'
                                              error={errors.claimInfo?.insuredFirstName}
                                              helperText={errors.claimInfo?.insuredFirstName}
                                              onChange={(e) => {
                                                form.change('claimInfo.insuredFirstName', e.target.value)

                                                if (values.claimInfo?.insuredIsTortfeasor) {
                                                  form.change('claimInfo.tortfeasorFullName', e.target.value)
                                                }
                                              }}
                                            />
                                            <Switches
                                              name="claimInfo.insuredIsTortfeasor"
                                              onChange={(e) => {
                                                const checked = e.target.checked;
                                                form.change('claimInfo.insuredIsTortfeasor', checked)
                                                if (checked) {
                                                  form.change('claimInfo.tortfeasorFullName', values?.claimInfo?.insuredFirstName)
                                                } else {
                                                  form.change('claimInfo.tortfeasorFullName', '')
                                                }
                                              }}
                                              data={{ label: 'Insured is tortfeasor', value: true }}
                                              color="secondary"
                                            />
                                            {!values.claimInfo?.insuredIsTortfeasor &&
                                              <Box
                                                sx={{
                                                  paddingLeft: 6
                                                }}
                                              >
                                                <TextField fullWidth required label="Tortfeasor" name='claimInfo.tortfeasorFullName' size='small'
                                                  error={errors.claimInfo?.tortfeasorFullName}
                                                  helperText={errors.claimInfo?.tortfeasorFullName}
                                                />
                                              </Box>
                                            }
                                          </Stack>
                                        </Grid>
                                      </Grid>
                                    </Stack>

                                    {/* loss details */}
                                    <Stack>
                                      <Typography variant="body1" color="primary" sx={{ mb: 1.5 }}>Loss details</Typography>
                                      <Grid container spacing={2}>
                                        <Grid xs={12} md={6}>
                                          <TextField
                                            name='claimInfo.dateOfLoss'
                                            fullWidth={true}
                                            inputProps={{ max: getCurrentDate() }}
                                            label="Date of loss" type="date"
                                            InputLabelProps={{ shrink: true }}
                                            size='small'
                                            required
                                            //we are using MUI textfield here b/c datepicker is better; therefore, we need to add below props to explicitly create controlled component
                                            onChange={e => form.change('claimInfo.dateOfLoss', e.target.value)}
                                            value={values?.claimInfo?.dateOfLoss}
                                            error={errors.claimInfo?.dateOfLoss}
                                            helperText={errors.claimInfo?.dateOfLoss}
                                          />
                                        </Grid>
                                        <Grid xs={12} md={6}>
                                          <TextField fullWidth label="Coverage" name='claimInfo.claimCoverage' select size='small'>
                                            {coverageList.map((option) => (
                                              <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                              </MenuItem>
                                            ))}
                                          </TextField>
                                        </Grid>
                                        <Grid xs={12} md={6}>
                                          <TextField fullWidth label="Loss state" name="claimInfo.lossState" size='small' select required>
                                            {[{ label: 'Select a state', value: 'none' }, ...stateLabelValues].map((option, idx) => (
                                              <MenuItem key={idx} value={option.value} disabled={idx === 0} >
                                                {option.label}
                                              </MenuItem>
                                            ))}
                                          </TextField>
                                        </Grid>
                                        <Grid xs={12} md={6}>
                                          <TextField fullWidth label="Loss county" name="claimInfo.lossCounty" size='small' />
                                        </Grid>
                                      </Grid>

                                    </Stack>

                                    <Stack>
                                      <Typography variant="body1" color="primary" sx={{ mb: 1.5 }}>Carrier details</Typography>
                                      <Grid container spacing={2}>

                                        <Grid xs={12}>
                                          <TextField fullWidth label="Carrier" name='recipientCarrier.carrierCommonName' size='small' disabled />
                                        </Grid>



                                        <Grid xs={12}>
                                          <TextField fullWidth label="Carrier phone number" name='recipientCarrier.contactInfo.phoneNumber' type='tel' size='small'
                                            onChangeCapture={(e) => e.target.value = formatPhoneNumber(e.target.value)}
                                            error={errors.recipientCarrier?.contactInfo?.phoneNumber}
                                            helperText={errors.recipientCarrier?.contactInfo?.phoneNumber}
                                          />
                                        </Grid>
                                        <Grid xs={12} md={6}>
                                          <TextField fullWidth label="Address line 1" name="recipientCarrier.contactInfo.address.street1" size='small' />
                                        </Grid>
                                        <Grid xs={12} md={6}>
                                          <TextField fullWidth label="Address line 2" name="recipientCarrier.contactInfo.address.street2" size='small' />
                                        </Grid>
                                        <Grid xs={12} md={4}>
                                          <TextField fullWidth label="City" name="recipientCarrier.contactInfo.address.city" size='small' />
                                        </Grid>
                                        <Grid xs={6} md={4}>
                                          <TextField fullWidth label="State" name="recipientCarrier.contactInfo.address.state" size='small' select>
                                            {stateLabelValues.map((option) => (
                                              <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                              </MenuItem>
                                            ))}
                                          </TextField>
                                        </Grid>
                                        <Grid xs={6} md={4}>
                                          <TextField fullWidth label="Zip" name="recipientCarrier.contactInfo.address.zipCode" size='small' />
                                        </Grid>

                                        <Grid xs={12} md={6}>
                                          <TextField fullWidth label="Adjuster first name" name='recipientAdjuster.firstName' size='small' />
                                        </Grid>
                                        <Grid xs={12} md={6}>
                                          <TextField fullWidth label="Adjuster last name" name='recipientAdjuster.lastName' size='small' />
                                        </Grid>
                                        <Grid xs={12} md={6}>
                                          <TextField fullWidth label="Adjuster phone" name='recipientAdjuster.contactInfo.phoneNumber' type='tel' size='small'
                                            onChangeCapture={(e) => e.target.value = formatPhoneNumber(e.target.value)}
                                            error={errors.recipientAdjuster?.contactInfo?.phoneNumber}
                                            helperText={errors.recipientAdjuster?.contactInfo?.phoneNumber}
                                          />
                                        </Grid>
                                        <Grid xs={12} md={6}>
                                          <TextField fullWidth label="Adjuster email" name='recipientAdjuster.contactInfo.emailAddress' type='email' size='small' />
                                        </Grid>
                                      </Grid>
                                    </Stack>

                                    {/* demand details */}
                                    <Stack>
                                      <Typography variant="body1" color="primary" sx={{ mb: 1.5 }}>Demand details</Typography>
                                      <Grid container spacing={2}>
                                        <Grid xs={12}>
                                          <Stack direction={'row'} spacing={2}>
                                            <FormControl fullWidth>
                                              <Radios
                                                color="secondary"
                                                sx={{ width: '100%' }}
                                                label="Display demand amount in letter?"
                                                name="demandDetails.displayDemandAmount"
                                                onChange={(e) => {
                                                  form.change(
                                                    'demandDetails.displayDemandAmount',
                                                    e.target.value === 'true'
                                                  );
                                                  if (e.target.value !== 'true') {
                                                    form.change('claimInfo.insuredPolicyLimitsAsUnderstoodByFirm', '');
                                                    form.change('demandDetails.demandOfferToSettleAmount', '');
                                                    form.change('demandDetails.policyLimitDemandIndicator', false);
                                                  } else {
                                                    form.change('demandDetails.policyLimitDemandIndicator', true);
                                                    form.change(
                                                      'demandDetails.demandOfferToSettleAmount',
                                                      values?.claimInfo?.insuredPolicyLimitsAsUnderstoodByFirm || ''
                                                    );
                                                  }
                                                }}
                                                data={[
                                                  { label: 'Yes', value: true },
                                                  { label: 'No', value: false },
                                                ]}
                                                radioGroupProps={{ row: true }}
                                                required
                                              />
                                            </FormControl>

                                            {values?.demandDetails?.displayDemandAmount !== false && (
                                              <FormControl fullWidth>
                                                <Radios
                                                  color="secondary"
                                                  sx={{ width: '100%' }}
                                                  label="Is this a policy limit demand?"
                                                  name="demandDetails.policyLimitDemandIndicator"
                                                  onChange={(e) => {
                                                    form.change(
                                                      'demandDetails.policyLimitDemandIndicator',
                                                      e.target.value === 'true'
                                                    );
                                                    if (e.target.value === 'true') {
                                                      form.change(
                                                        'demandDetails.demandOfferToSettleAmount',
                                                        values?.claimInfo?.insuredPolicyLimitsAsUnderstoodByFirm
                                                      );
                                                    } else {
                                                      form.change('demandDetails.demandOfferToSettleAmount', '');
                                                    }
                                                  }}
                                                  data={[
                                                    { label: 'Yes', value: true },
                                                    { label: 'No', value: false },
                                                  ]}
                                                  radioGroupProps={{ row: true }}
                                                />
                                              </FormControl>
                                            )}
                                          </Stack>
                                        </Grid>
                                        <Grid xs={12} md={6}>
                                          <TextField
                                            label="Insured policy limit"
                                            name='claimInfo.insuredPolicyLimitsAsUnderstoodByFirm'
                                            onChangeCapture={(e) => e.target.value = formatDecimal(e.target.value)}
                                            InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment>, }}
                                            onChange={(e) => {
                                              if (values?.demandDetails?.policyLimitDemandIndicator) {
                                                form.change("demandDetails.demandOfferToSettleAmount", e.target.value)
                                              }
                                              form.change("claimInfo.insuredPolicyLimitsAsUnderstoodByFirm", e.target.value)
                                            }}
                                            size='small'
                                          />
                                        </Grid>
                                        <Grid xs={12} md={6}>
                                          <TextField
                                            fullWidth
                                            label="Demand amount"
                                            name='demandDetails.demandOfferToSettleAmount'
                                            onChangeCapture={(e) => e.target.value = formatDecimal(e.target.value)}
                                            InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment>, }}
                                            disabled={values?.demandDetails?.policyLimitDemandIndicator}
                                            size='small'
                                          />
                                        </Grid>
                                        <Grid xs={12}>

                                        </Grid>
                                        <Grid xs={12} md={6}>
                                          <TextField
                                            name='demandDetails.demandResponseDueDate'
                                            fullWidth={true}
                                            inputProps={{ min: getCurrentDate(), max: moment().add(100, 'years').format('YYYY-MM-DD') }}
                                            label="Demand response due date"
                                            type="date"
                                            InputLabelProps={{ shrink: true }}
                                            size='small'
                                            //we are using MUI textfield here b/c datepicker is better; therefore, we need to add below props to explicitly create controlled component
                                            onChange={e => form.change('demandDetails.demandResponseDueDate', e.target.value)}
                                            value={values?.demandDetails?.demandResponseDueDate}
                                            error={errors.demandDetails?.demandResponseDueDate}
                                            helperText={errors.demandDetails?.demandResponseDueDate}
                                          />
                                        </Grid>
                                        <Grid xs={12} md={6}>
                                          <TextField fullWidth label="Tax ID" name="sendingFirm.firmTINNumber" size='small' />
                                        </Grid>
                                      </Grid>
                                    </Stack>

                                    {/* case team */}
                                    <Stack>
                                      <Typography variant="body1" color="primary" sx={{ mb: 1.5 }}>Case team</Typography>
                                      <Grid container spacing={2}>

                                        <Grid item xs={12}>


                                          <Grid item xs={12}>
                                            <Autocomplete
                                              name="autoCompletes.attorney"
                                              //no need for explicitly controlled component here; RFF is handling with state management, including: value, onChange, error, and helperText
                                              fullWidth
                                              blurOnSelect={true}
                                              options={attorney_auto_complete_data}
                                              filterSelectedOptions={true}
                                              filterOptions={(options, params) => getSingleSelectAutoCompleteFilteredOptions(options, params)}
                                              isOptionEqualToValue={(option, value) => option.label === value.label}
                                              renderInput={(params) => (
                                                <>
                                                  <MuiTextField
                                                    {...params}
                                                    size='small'
                                                    label="Attorney"
                                                    required={true}
                                                    onClick={(e) => form.blur('autoCompletes.attorney')}
                                                    error={touched['autoCompletes.attorney'] && errors.autoCompletes?.attorney}
                                                    helperText={touched['autoCompletes.attorney'] && errors.autoCompletes?.attorney}

                                                  />
                                                </>
                                              )}
                                            />
                                          </Grid>

                                        </Grid>

                                        <Grid xs={12}>
                                          <Autocomplete
                                            name="autoCompletes.collaborators"
                                            //no need for explicitly controlled component here; RFF is handling with state management, including: value, onChange, error, and helperText
                                            fullWidth
                                            multiple
                                            limitTags={4}
                                            filterOptions={(options, params) => getMultiSelectAutoCompleteFilteredOptions(options, params, values?.autoCompletes?.collaborators)}
                                            options={collaborators_auto_complete_data}
                                            renderInput={(params) => (
                                              <>
                                                <MuiTextField
                                                  //MUI-RFF textfield does filtering does not work well with multi select autocomplete, so we use MUI textfield here
                                                  {...params}
                                                  size='small'
                                                  label="Collaborators"
                                                />
                                              </>
                                            )}
                                          />
                                        </Grid>

                                        <Grid xs={12}>
                                          <Switches
                                            name="mutators.attorneyIsPrimaryContact"
                                            onChange={(e) => {
                                              const checked = e.target.checked;
                                              form.change('mutators.attorneyIsPrimaryContact', checked)
                                              form.change('autoCompletes.primaryContact', checked ? values?.autoCompletes?.attorney : undefined)
                                            }}
                                            data={{ label: 'Attorney is primary contact', value: true }}
                                            color="secondary"
                                          />
                                        </Grid>
                                        {!values?.mutators?.attorneyIsPrimaryContact &&

                                          <Grid item xs={12}>
                                            <Autocomplete
                                              name="autoCompletes.primaryContact"
                                              //no need for explicitly controlled component here; RFF is handling with state management, including: value, onChange, error, and helperText
                                              fullWidth
                                              blurOnSelect={true}
                                              filterSelectedOptions={true}
                                              filterOptions={(options, params) => getSingleSelectAutoCompleteFilteredOptions(options, params)}
                                              isOptionEqualToValue={(option, value) => option.label === value.label}
                                              options={primaryContact_auto_complete_data}
                                              renderInput={(params) => (
                                                <>
                                                  <MuiTextField
                                                    {...params}
                                                    size='small'
                                                    label="Primary contact"
                                                    required={true}
                                                    onClick={(e) => form.blur('autoCompletes.primaryContact')}
                                                    error={touched['autoCompletes.primaryContact'] && errors.autoCompletes?.primaryContact}
                                                    helperText={touched['autoCompletes.primaryContact'] && errors.autoCompletes?.primaryContact}
                                                  />
                                                </>
                                              )}
                                            />
                                          </Grid>

                                        }
                                      </Grid>
                                    </Stack>

                                    {/* response address */}
                                    <Stack>
                                      <Typography variant="body1" color="primary" sx={{ mb: 1.5 }}>Response address</Typography>
                                      <Grid container spacing={2}>
                                        <Grid xs={12} md={6}>
                                          <TextField fullWidth label="Address line 1" name="demandDetails.demandResponseAddress.street1" size='small' />
                                        </Grid>
                                        <Grid xs={12} md={6}>
                                          <TextField fullWidth label="Address line 2" name="demandDetails.demandResponseAddress.street2" size='small' />
                                        </Grid>
                                        <Grid xs={12} md={4}>
                                          <TextField fullWidth label="City" name="demandDetails.demandResponseAddress.city" size='small' />
                                        </Grid>
                                        <Grid xs={6} md={4}>
                                          <TextField fullWidth label="State" name="demandDetails.demandResponseAddress.state" select size='small'>
                                            {stateLabelValues.map((option) => (
                                              <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                              </MenuItem>
                                            ))}
                                          </TextField>
                                        </Grid>
                                        <Grid xs={6} md={4}>
                                          <TextField fullWidth label="Zip" name="demandDetails.demandResponseAddress.zipCode" size='small' />
                                        </Grid>
                                      </Grid>
                                    </Stack>
                                  </Stack>
                                </Grid>
                              )}

                              {tabValue === 1 && (
                                <Grid container spacing={2} sx={{ mb: 5 }}>
                                  <Stack spacing={2} sx={{ mt: 3, mb: 5, width: '100%' }}>
                                    <TextField fullWidth label="Select template" name='metadata.demandTemplateId' select size='small' required
                                      helperText={viewType === PRECEDENT_VIEW_TYPE && `Version ${currentTemplate?.version}`}
                                      onChange={(e) => {
                                        const value = e.target.value;
                                        if (!value) return;
                                        form.change('metadata.demandTemplateId', value);
                                        if (noTemplatedInitially && value !== NO_TEMPLATE) {
                                          setTemplateDialogState({ ...templateDialogState, confirmTemplateChange: { ...templateDialogState.confirmTemplateChange, open: true } });
                                        } else if (!noTemplatedInitially && value === NO_TEMPLATE) {
                                          setTemplateDialogState({ ...templateDialogState, confirmNoTemplate: { ...templateDialogState.confirmNoTemplate, open: true } });
                                        } else {
                                          setCurrentTemplate(
                                            availableTemplates.find((template) => template.templateId === value)
                                          );
                                        }
                                      }}
                                    >
                                      {availableTemplates.map((option) => {
                                        return (
                                          <MenuItem key={option.templateId} value={option.templateId}>
                                            {option.templateName}
                                          </MenuItem>
                                        )
                                      })}
                                    </TextField>

                                    {shouldShowTemplateUpdateAlert && (
                                      <Box
                                        sx={{
                                          mb: 1
                                        }}
                                      >
                                        <TemplateUpdateAlert user={user} templateBody={templateBody}
                                          pinnedTemplateBody={templateBodyForPinnedVersion} documentMetaData={documentMetaData} viewType={viewType} />
                                      </Box>
                                    )}

                                    <Dialog
                                      open={templateDialogState?.confirmNoTemplate?.open || templateDialogState?.confirmTemplateChange?.open}
                                      onClose={handleTemplateDialogClose}>
                                      <DialogContent>

                                        <Stack spacing={2}>
                                          <Typography variant='body1' color="primary">{templateDialogState?.confirmNoTemplate?.open ? `${templateDialogState.confirmNoTemplate.text1}` : `${templateDialogState.confirmTemplateChange.text1}`}
                                          </Typography>

                                          <Typography variant='body1' color="primary">
                                            {templateDialogState?.confirmNoTemplate?.open ? `${templateDialogState?.confirmNoTemplate?.text2}` : `${templateDialogState.confirmTemplateChange.text2}`}
                                          </Typography>
                                        </Stack>


                                      </DialogContent>
                                      <DialogActions>
                                        <Button onClick={handleTemplateCancelChange} color="secondary">
                                          {templateDialogState?.confirmNoTemplate?.open ? `${templateDialogState.confirmNoTemplate.buttonText1}` : `${templateDialogState.confirmTemplateChange.buttonText1}`}
                                        </Button>
                                        <Button onClick={handleTemplateOkChange} variant="contained" color="secondary">
                                          {templateDialogState?.confirmNoTemplate?.open ? `${templateDialogState.confirmNoTemplate.buttonText2}` : `${templateDialogState.confirmTemplateChange.buttonText2}`}
                                        </Button>
                                      </DialogActions>
                                    </Dialog>

                                    {currentTemplate != null && currentTemplate.templateId !== NO_TEMPLATE &&
                                      <Divider flexItem style={{ width: '100%' }} sx={{ mt: 2 }} />
                                    }

                                    {currentTemplate != null && currentTemplate.templateId !== NO_TEMPLATE && customerDemandFields.map(({ friendlyName, fieldName, dataType, aiDataSourceConfig, aiGenerationPrompt }, index) => (
                                      currentTemplate.customFieldsList.includes(fieldName) && (<Stack key={fieldName} spacing={.5}>
                                        <Stack direction={'row'} spacing={1} alignItems={'center'}>
                                          {aiDataSourceConfig && aiGenerationPrompt !== '' && <AutoAwesome sx={{ color: deepPurple[900] }} fontSize='md' />}
                                          <Typography variant="body1" sx={{ mb: 1.5 }}>
                                            {friendlyName}
                                          </Typography>
                                        </Stack>

                                        <Field name={`customDemandFieldsList[${index}]`}>
                                          {dataType === 'htmltext' &&
                                            (({ input }) => (
                                              <ConfiguredRichTextEditor
                                                content={input.value.fieldValue}
                                                onChange={debounce(({ editor }) => input.onChange({ fieldName, dataType, fieldValue: editor.getHTML() }), 1000)}
                                                showGenerateButton={aiDataSourceConfig && aiGenerationPrompt !== ''}
                                                disabled={documentMetaData.documentStatus === 'AwaitingMedicalExtraction'}
                                                fieldName={fieldName}
                                                documentId={documentId}
                                                user={user}
                                              />
                                            ))}
                                        </Field>
                                      </Stack>)
                                    ))}
                                  </Stack>
                                </Grid>
                              )}
                            </Stack>
                          )}

                          <ConfirmationDialog
                            handleOk={() => {
                              closeEditView();
                            }}
                            {...confirmationDialogProps}
                          />

                        </div>
                      </ResizableWrapWithPdfViewer>
                    )}
                  </div>
                </DialogContent>
              </form>
            );
          }}
        />
      </Dialog>
    </div>
  );
};

export default EditDemandDetails;
