import React, { useEffect } from 'react';
import { useRichTextEditorContext } from 'mui-tiptap';
import { LoadingButton } from '@mui/lab';
import { AutoAwesome } from '@mui/icons-material';
import { deepPurple } from '@mui/material/colors';
import Tooltip from '@mui/material/Tooltip';

const MenuButtonGenerate = ({ fieldName, documentId, user, onMouseDown, setAiLoading, disabled }) => {
  const editor = useRichTextEditorContext();

  // Monitor the isLoading state in the editor's storage
  useEffect(() => {
    const isLoading = editor.storage.aiGenerate.isLoading;

    // Update the AiLoading state based on the editor's loading status
    setAiLoading(isLoading);

    // Optionally, add a cleanup function if needed
    return () => {
      setAiLoading(false); // Reset loading state on unmount if necessary
    };
  }, [editor.storage.aiGenerate.isLoading, setAiLoading]);

  const handleClick = () => {
    editor.commands.generateNarrative(fieldName, documentId, user);

  };
  
  const isDisabled = fieldName !== 'FactsOfLoss' && disabled; // We always want the button enabled for the FactsOfLoss field

  return (
    <Tooltip
      title={isDisabled ? "Please wait for the demand to process to enable" : ""}
      arrow
    >
      <span> {/* This is necessary because a disabled button does not trigger hover events directly */} 
        <LoadingButton
          sx={{
            backgroundColor: deepPurple[50],
            color: deepPurple[900],
            '&:hover': {
              backgroundColor: deepPurple[100]
            }
          }}
          size="small"
          startIcon={<AutoAwesome fontSize="small" sx={{ color: deepPurple[900] }} />}
          loading={editor.storage.aiGenerate.isLoading}
          onClick={handleClick}  // Use the handleClick function
          onMouseDown={onMouseDown}
          disabled={isDisabled}
        >
          Compose
        </LoadingButton>
      </span>
    </Tooltip>
  );
};

export default MenuButtonGenerate;
